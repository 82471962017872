var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs__content"},[_c('document-table',{attrs:{"documents":_vm.documentList,"docsProps":_vm.docsProps},on:{"update:docsProps":function($event){_vm.docsProps=$event},"update:docs-props":function($event){_vm.docsProps=$event},"send":function (docNumber) {
        _vm.currentDocNumber = docNumber;
        _vm.showDocSendingDialog = true;
      },"download":function (docNumber) {
        _vm.currentDocNumber = docNumber;
        _vm.showDocDownloadingDialog = true;
      }}}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocSendingDialog,"doc-number":_vm.currentDocNumber,"is-send-mode":true},on:{"agree":function (ref) {
        var email = ref.payload;

        _vm.closeAllDialogs();
        _vm.targetEmail = email;
        _vm.useDocSendingDialogNotice = !_vm.useDocSendingDialogNotice;
      },"disagree":_vm.closeAllDialogs}}),_c('doc-getter-dialog',{attrs:{"show-dialog":_vm.showDocDownloadingDialog,"doc-number":_vm.currentDocNumber},on:{"agree":_vm.closeAllDialogs,"disagree":_vm.closeAllDialogs}}),_c('x-notice',{attrs:{"show":_vm.useDocSendingDialogNotice}},[_vm._v(" Документы успешно отправлены на email: "+_vm._s(_vm.targetEmail)+" ")]),_c('v-card',[_c('v-card-title',[_vm._v("Внесение средств в счет будущих платежей")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('payment-form',{attrs:{"amount":_vm.amount,"total":_vm.total,"email":_vm.userEmail,"docs":_vm.docs}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('help-card')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }