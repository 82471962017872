








import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import DocumentsTabContent from "@/components/for-documents-view/DocumentsTabContent.vue";
import ContractDocumentsTabContent from "@/components/for-documents-view/ContractDocumentsTabContent.vue";
import PaymentForm from "@/components/for-payments-view/PaymentForm.vue";
import {paymentForm} from "@/assets/scripts/form-fields/main";
import HelpCard from "@/components/for-payments-view/HelpCard.vue";
import {mapGetters, mapState} from "vuex";
import NotificationsTabContent from "@/components/for-documents-view/NotificationsTabContent.vue";

const { values } = paymentForm;
@Component({
  components: {
    PaymentForm,
    Page,
    XTabs,
    DocumentsTabContent,
    ContractDocumentsTabContent,
    NotificationsTabContent,
    HelpCard
  },
  computed: {
    ...mapState({
        appEnviroment: "appEnviroment",
    }),
    ...mapGetters({
      email: "user/email",
    }),
  },
})
class Documents extends Vue {
  [x: string]: any
  values = values

  titles = ["Документы", "Документы договора", "Претензии и уведомления"];

  components = [DocumentsTabContent, ContractDocumentsTabContent, NotificationsTabContent];

  tab = 0;

  mounted() {
    this.values = {...this.values, email: this.userEmail, amount: this.amount, total: this.total};
    this.defaultValues = {...this.values, email: this.userEmail, amount: this.amount, total: this.total};
  }

  get amount() {
    return this.$route.params.amount || 0
  }

  get total() {
    const { appEnviroment } = this;
    return +this.amount + (+this.amount * (+appEnviroment.constants["PaymentFee"] || 0))
  }

  get userEmail() {
    return this.email
  }

  get docs() {
    return this.$route.params.docs || ''
  }
}

export default Documents;
