


























































import {Component, Emit, Mixins, Prop, Watch} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {formatDate} from "@/lib/date";
import {NotificationsByContract} from "@/models/contract";
import XDataTable from "../hoc/Table.vue";
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import XButton from "../SimpleButton.vue";
import NotificationCard from "@/components/for-documents-view/NotificationCard.vue";
import XSlider from "../SimpleSlider.vue";
import {NotificationFileAsReqBody} from "@/models/app-api";
import {AxiosResponse} from "axios";
import AppApiMixin from "@/components/mixins/AppApi.vue";


@Component({
  components: {
    XDataTable,
    XDropdownWithDatePicker,
    XSlider,
    XButton,
    NotificationCard
  },
  computed: {
    ...mapGetters({contractId: "contract/id"}),
  },
  filters: {
    formatDate(value: string): string {
      return formatDate(value, "full");
    },
  },
})
class NotificationTable extends Mixins(AppApiMixin) {
  [x: string]: any;

  showTextMessage = false;

  @Prop({required: true}) readonly notifications!: NotificationsByContract[];

  headers = [
    {text: "Документ", value: "type", width: "55%", align: "start"},
    {text: "Дата рассылки", value: "date", width: "15%", align: "start"},
    {text: "Получатели", value: "comment", width: "20%", align: "start"},
    {text: "", value: "file", width: "10%", align: "end"}
  ];

  docKeys = [
    "название",
    "датавремя",
    "адресдоставки",
    ["естьвложения", "$номерЗаписи"]
  ];

  selectedRows: boolean[] = [];
  dates: string[] = [];
  textMessage = '';
  nameMessage = '';

  public get runUpdate(): string {
    const {dates} = this;

    return [dates.join(" - ")].join(" | ");
  }

  public get preparedHeaders() {
    return this.headers.slice(0, this.docKeys.length);
  }

  public get preparedNotifications() {
    return this.notifications.map((document) => {
      return this.docKeys.map((keyByTd) => {
        return Array.isArray(keyByTd)
            ? keyByTd.map((key) => document[key])
            : document[keyByTd];
      });
    });
  }

  @Watch("runUpdate")
  public runUpdateChanged() {
    this.emitUpdateEvt();
  }

  @Emit()
  public emitUpdateEvt() {
    const [from, to] = this.dates;

    this.$emit("update:docsProps", {
      contractId: this.contractId,
      from,
      to,
    });
  }

  public showMessage(index) {
    this.showTextMessage = true
    this.nameMessage = this.notifications[index]["название"]
    this.textMessage = this.notifications[index]["текст"]
  }

  public closeMessage(index) {
    this.showTextMessage = false
  }

  public stopPropagation(event) {
    event.stopPropagation();
  }

  public getNotificationFile(notificationId) {
    const {contractId} = this;
    const reqBody: NotificationFileAsReqBody = {
      contractId: contractId,
      fileId: notificationId,
      download: true
    };
    this.getFileNotificationByContract(reqBody)
        .then((response) => {
          this.downloadDocument(response);
        })
  }

  public downloadDocument(response: AxiosResponse): void {
    const contentDisposition: string = response.headers["content-disposition"];
    const { data } = response;
    const link = document.createElement('a')
    link.href = this.createObjectURL(data);
    link.download = this.getDocName(contentDisposition);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  public createObjectURL(object) {
    let binaryData = [];
    binaryData.push(object);
    const blob = new Blob(binaryData)
    return (window.URL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
  }

  public getDocName(contentDisposition: string): string {
    const dispositionWithDocName = contentDisposition
        .split(";")
        .find((disposition) => disposition.includes("filename"));

    let docName = "some-doc";

    if (dispositionWithDocName) {
      const matched = dispositionWithDocName
          .trim()
          .replace(/"/g, "")
          .match(/[a-z\d]+\.[a-z\d]+$/i);
      docName = matched ? matched[0] : docName;
    }
    return docName;
  }
}

export default NotificationTable;
