









import { Component, Mixins, Watch } from "vue-property-decorator";
import {NotificationsByContract} from "@/models/contract";
import NotificationTable from "@/components/for-documents-view/NotificationTable.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {NotificationsAsReqBody} from "@/models/app-api";

@Component({
  components: {
    NotificationTable
  },
})
class Notifications extends Mixins(AppApiMixin) {
  docsProps: NotificationsAsReqBody = {
    contractId: -1,
    from: "",
    to: "",
  };

  notificationsList: NotificationsByContract[] = [];

  @Watch("docsProps")
  public docsPropsChanged() {
    this.getNotificationsByContract(
        this.docsProps,
        (notificationsList: NotificationsByContract[]) => {
          this.notificationsList = notificationsList;
        }
    );
  }
}

export default Notifications;
