








import { Component, Prop, Vue } from "vue-property-decorator";
import XCard from "../SimpleCard.vue";

@Component({
  components: {
    XCard
  },
})
class NotificationCard extends Vue {
  [x: string]: any
  @Prop({ required: true }) readonly textMessage!: string;
}

export default NotificationCard;
