var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{staticClass:"data-table_type_notifications",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedNotifications,"use-item-slot":true,"single-select":true},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('div',{staticClass:"data-table__head-content"},[_c('x-dropdown-with-date-picker',{attrs:{"dates":_vm.dates},on:{"update:dates":function($event){_vm.dates=$event}}})],1)]},proxy:true},{key:"item",fn:function(ref){
var ref_item = ref.item;
var index = ref_item.index;
var item = ref_item.item;
return [_c('tr',{class:[
        'data-table__text',
        'data-table__text_cursor_default',
        _vm.selectedRows[index] && 'data-table__text_active_base' ]},_vm._l((item),function(value,header){return _c('td',{key:("" + header),on:{"click":function($event){return _vm.showMessage(index)}}},[(['name', 'comment', 'type'].indexOf(header) >= 0)?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(['date'].indexOf(header) >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]):_vm._e(),(['file'].indexOf(header) >= 0 && value[0] == 1)?[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":[function($event){return _vm.getNotificationFile(value[1])},function($event){$event.preventDefault();return _vm.stopPropagation($event)}]}},[_c('v-icon',{staticClass:"dropdown__icon"},[_vm._v("mdi-download")]),_c('span',{staticClass:"secondary--text"},[_vm._v("Скачать")])],1)]:_vm._e()],2)}),0),_c('x-slider',{attrs:{"show":_vm.showTextMessage,"container-mod-class":"split-container_width_max","box-mod-class":"split-container__main_with_card","title":("" + _vm.nameMessage)},on:{"update:show":function($event){_vm.showTextMessage=$event},"close":_vm.closeMessage}},[_c('notification-card',{attrs:{"textMessage":_vm.textMessage}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }